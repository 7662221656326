import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  private _withDate(): string {
    return `<log> ${new Date().toLocaleString()} |`;
  }
  info(template: any, ...optionalParams: any[]): void {
    if (!isDevMode()) return;
    console.info(this._withDate(), template, ...optionalParams);
  }

  error(template: any, ...optionalParams: any[]): void {
    if (!isDevMode()) return;
    console.error(this._withDate(), template, ...optionalParams);
  }

  warn(template: any, ...optionalParams: any[]): void {
    if (!isDevMode()) return;
    console.warn(this._withDate(), template, ...optionalParams);
  }
}
