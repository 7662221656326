export const environment = {
  mode: 'development',
  name: 'FastFisio',
  organizationId: '664d1c3fc5e353b875c7f442',
  languageId: '664ce03a981ee46c14e6d7be',
  googleApiKey: 'AIzaSyCgfHDkUaIccuOsVYhEQfoWmIbs6nqGmhY',
  baseUrl: 'https://dev.fastfisio.com.br',
  shoppinggooUrl: 'https://dev.fastfisio.com.br/shoppinggoo',
  globalgooUrl: 'https://dev.fastfisio.com.br/globalgoo',
  organizationgooUrl: 'https://dev.fastfisio.com.br/organizationgoo',
  servicegooUrl: 'https://dev.fastfisio.com.br/servicegoo',
  routergooUrl: 'https://dev.fastfisio.com.br/routergoo',
};
